angular.module('cutlist').factory('Inventory', ['$http', 'BaseEntity', function ($http, BaseEntity) {
    var Inventory = function (inventory) {
        this.obj = inventory;
        this.savedObj = cloneDeep(inventory);
    };

    Object.assign(Inventory.prototype, BaseEntity.prototype);
    Inventory.prototype.constructor = Inventory;

    Inventory.prototype.save = function (callback) {
        var patch = createPatch(this.savedObj, this.obj);
        $http.patch('/inventories/' + this.obj._id, patch)
            .then(({data: obj}) => {
                this.obj = obj;
                this.savedObj = cloneDeep(obj);
                callback(null, obj);
            }, err => this.processError(err, null, callback));
    };

    Inventory.prototype.delete = function (callback) {
        if (!window.isLoggedIn) {
            return callback();
        }

        $http.delete('/inventories/' + this.obj._id)
            .then(() => callback(), err => this.processError(err, null, callback));
    };

    Inventory.prototype.resetChanges = function () {
        this.obj = cloneDeep(this.savedObj);
    };

    Inventory.prototype.hasChanged = function () {
        var changed = hasChanged(this.savedObj, this.obj);
        return changed;
    };

    function createPatch (savedObj, obj) {
        if (!savedObj || !obj) {
            return {};
        }

        obj._id = savedObj._id;
        obj.creation_date = savedObj.creation_date;
        return window.JSON8Patch.diff(savedObj, obj);
    }

    function hasChanged (savedObj, obj) {
        var diff = savedObj && window.JSON8Patch.diff(savedObj, obj);
        if (!diff || !diff.length) {
            return false;
        }

        return diff.length !== 1 || diff[0].path === ''; // XXX: investigate why this patches appear: op:"add", path:"", value:null
    }

    function cloneDeep (obj) {
        return window._.cloneDeep(obj);
    }

    return Inventory;
}]);
