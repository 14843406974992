angular.module('cutlist')
.directive('confirmMessage', ['$rootScope', ($rootScope) => {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            text: '='
        },
        link: ($scope, el) => {
            $rootScope.showConfirm = async function (text, cb) {
                $scope.text = text;
                el['0'].show();
                $scope.callback = cb;
            };

            if(el['0']) {
                window.addEventListener('click', function(e){
                    if(el['0'].open) {
                        if(e.target.id === 'confirm'){
                            el['0'].close();
                        }
                    }
                }, false);
            }

            $scope.confirmMessage = async (event, ok) => {
                event.preventDefault();
                el['0'].close();
                $scope.callback(ok);
            }

        },
        templateUrl: '/views/partials/confirm-message.html'
    };
}]);

