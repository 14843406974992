var app = angular.module('cutlist');

app.config(['$uibTooltipProvider', function($uibTooltipProvider) {
    $uibTooltipProvider.options({
        appendToBody: false
    });
}]);

app.controller('CutListCtrl', [
    '$rootScope',
    '$scope',
    '$http',
    '$timeout',
    '$window',
    '$interpolate',
    '$compile',
    '$sce',
    '$uibModal',
    'Projects',
    'Inventories',
    'Errors',
    '$translate',
    (
        $rootScope,
        $scope,
        $http,
        $timeout,
        $window,
        $interpolate,
        $compile,
        $sce,
        $uibModal,
        Projects,
        Inventories,
        Errors,
        $translate
    ) => {
        $scope.$watch('project', function(project) {
            if (!project) {
                return;
            }

            $scope.leftColLabel = project.obj.name;
        });

        $scope.$watch('projectSettings', function (projectSettings) {
            if (projectSettings) {
                $scope.setResultDirty();
            }
        }, true);

        const cookieKey = $translate.proposedLanguage();

        $translate.use(cookieKey);
        $translate.onReady()
            .then(() => {
                init();
            })
            .catch(console.error);

        $scope.tabs = [];
        var pdfListener;
        $scope.registerPdfListener = function(listener) {
            pdfListener = listener;
        };

        $scope.projects = [];

        //Observe which main wrapper div: left, right or middle is in view
        let options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.4,
        };

        function init () {
            emptyResult((err, result) => {
                if (err) {
                    return $scope.handleError(err);
                }

                $scope.sheets = {};

                $scope.projectSettings = {
                    cutType: 'h',
                    sawWidth: 5,
                    trimLeft: 10,
                    trimTop: 10,
                    trimRight: 10,
                    trimBottom: 10,
                    edgeOverMeasure: 10
                };
                $scope.active = 'Projects';
                $scope.leftColLabel = 'Select_project';

                $scope.comms = {
                    detailsDone: false
                };

                $scope.result = result;
                initCutlist();
            });
        }

        $scope.changeLanguage = function(ev, langKey) {

            $translate.use(langKey)
                .then(() => {
                    $scope.hot.updateSettings({
                        colHeaders: [
                            `${$translate.instant('Number')}`,
                            `${$translate.instant('Detail_name')}`,
                            `${$translate.instant('Count_abr')}`,
                            '∥',
                            '⊥',
                            `${$translate.instant('Ilong')}`,
                            `${$translate.instant('Ishort')}`,
                            `${$translate.instant('IIlong')}`,
                            `${$translate.instant('IIshort')}`,
                            `${$translate.instant('Turn')}`,
                            '',
                            ''
                        ]
                    });
                })
                .catch(console.error);
        };

        function initCutlist() {
            // For new design, more work on that needed
            const VISUALIZATION_DIMENTION_FACTOR = 3;
            const NUMBER_COL = 2;
            const WIDTH_COL = 3;
            const HEIGHT_COL = 4;
            const EDGE_FL_COL = 5;
            const EDGE_FS_COL = 6;
            const EDGE_SL_COL = 7;
            const EDGE_SS_COL = 8;
            const DISABLE_COL = 10;
            const DELETE_COL = 11;

            $scope.inventoryNew = {
                width: 2800,
                height: 2070
            };

            $scope.colHeaders = [
                `${$translate.instant('Number')}`,
                `${$translate.instant('Detail_name')}`,
                `${$translate.instant('Count_abr')}`,
                '∥',
                '⊥',
                `${$translate.instant('Ilong')}`,
                `${$translate.instant('Ishort')}`,
                `${$translate.instant('IIlong')}`,
                `${$translate.instant('IIshort')}`,
                `${$translate.instant('Turn')}`,
                '',
                ''
            ];

            $scope.detailsOptions = [
                'A',
                'B',
                'C',
                'D',
                'E'
            ];
            $scope.toggleValues = [0, 1, 2];
            $scope.toggleValues2 = [false, true];
            $scope.toggleVisualValues2 = [`${$translate.instant('No')}`, `${$translate.instant('Yes')}`];

            $scope.details = [{
                name: 'A',
                number: 2,
                width: 500,
                height: 900,
                edgefl: 2,
                edgefs: 0,
                edgesl: 1,
                edgess: 1,
                rotate: false
            }, {
                name: 'B',
                number: 5,
                width: 500,
                height: 900,
                edgefl: 2,
                edgefs: 0,
                edgesl: 1,
                edgess: 1,
                rotate: false
            }, {
                name: 'C',
                number: 2,
                width: 500,
                height: 652,
                edgefl: 1,
                edgefs: 1,
                edgesl: 2,
                edgess: 2,
                rotate: false
            }, {
                name: 'D',
                number: 1,
                width: 500,
                height: 652,
                edgefl: 1,
                edgefs: 1,
                edgesl: 2,
                edgess: 2,
                rotate: false
            }];

            $scope.stocks = [];
            $scope.inventories = [];

            $scope.addRow = () => {
                let name = 'A';
                if ($scope.details.length) {
                    name = $scope.details[$scope.details.length - 1].name;
                }
                $scope.details.push({
                    name: name,
                    number: 1,
                    width: 0,
                    height: 0,
                    rotate: false,
                    edgefl: 0,
                    edgefs: 0,
                    edgesl: 0,
                    edgess: 0
                });
            };

            $scope.deleteRow = (index) => {
                $scope.details.splice(index, 1);
                $scope.details.forEach((ign, idx) => idx >= index && $scope.recompileTooltip(idx));
            };

            $scope.deactivateRow = (index) => {
                $scope.details[index].disabled = !$scope.details[index].disabled;
            };

            $scope.tooltipContents = [];

            $scope.recompileAllTooltips = () => {
                ($scope.details || []).forEach((ign, idx) => $scope.recompileTooltip(idx));
            };

            $scope.recompileTooltip = idx => {
                var value = $scope.details[idx];

                var pixelWidth = Math.round(value.width / VISUALIZATION_DIMENTION_FACTOR);
                var pixelHeight = Math.round(value.height / VISUALIZATION_DIMENTION_FACTOR);
                var tooltip = {
                    width: value.width,
                    height: value.height,
                    label: value.name.toLowerCase().substring(0, 3) + '.',
                    pxWidth: pixelWidth + 'px',
                    pxHeight: pixelHeight + 'px',
                    innerPxWidth: pixelWidth - 9 + 'px',
                    innerPxHeight: pixelHeight - 9 + 'px',
                    borderTop: getBorder(value.edgefl),
                    borderRight: getBorder(value.edgefs),
                    borderBottom: getBorder(value.edgesl),
                    borderLeft: getBorder(value.edgess),
                    halfWidth: pixelWidth / 2 - 9 + 'px',
                    halfHeight: pixelHeight / 2 - 9 + 'px'
                };
                $scope.tooltipContents[idx] = tooltip;
            };

            $scope.columnsDefinitions = [{
                data: 'id',
                type: 'numeric',
                readOnly: true,
                renderer: hotRenderer,
                className: 'htCenter',
                width: 20
            }, {
                data: 'name',
                type: 'autocomplete',
                source: $scope.detailsOptions
            }, {
                data: 'number',
                type: 'numeric',
                className: 'htCenter'
            }, {
                data: 'width',
                type: 'numeric',
                className: 'htCenter'
            }, {
                data: 'height',
                type: 'numeric',
                className: 'htCenter'
            }, {
                data: 'edgefl',
                type: 'numeric',
                className: 'htCenter',
                readOnly: true
            }, {
                data: 'edgefs',
                type: 'numeric',
                className: 'htCenter',
                readOnly: true
            }, {
                data: 'edgesl',
                type: 'numeric',
                className: 'htCenter',
                readOnly: true
            }, {
                data: 'edgess',
                type: 'numeric',
                className: 'htCenter',
                readOnly: true
            }, {
                data: 'rotate',
                type: 'checkbox',
                className: 'htCenter'
            }, {
                readOnly: true,
                renderer: hotDisableRenderer
            }, {
                readOnly: true,
                renderer: hotDeleteRenderer
            }];

            $scope.editableChanged = function(idx) {
                if ($scope.tooltipTemplate) {
                    $timeout(() => {
                        $scope.recompileTooltip(idx);
                    });
                }
            };

            $scope.askPreventEdit = function() {
                if (hasResult()) {
                    if (askToResetCuttingPlan()) {
                        delete $scope.project.result;
                        emptyResult((err, result) => {
                            if (err) {
                                return cb(err);
                            }
                            $scope.result = result;
                        });

                        return false;
                    } else {
                        return true;
                    }
                }

                return false;
            };

            function hasResult() {
                return $scope.result && !!$scope.result.arr.length;
            }

            $scope.changeStock = function(index, val) {
                $scope.stocks[index].number += val;
                if (!$scope.stocks[index].number) {
                    $scope.stocks.splice(index, 1);
                }

                $scope.setResultDirty();
            };

            $scope.changeSelectedStock = function() {
                $scope.sheets.sheetArr = $scope.inventories.map(i => ({
                    id: i.obj._id,
                    label: i.obj.width + ' x ' + i.obj.height
                }));

                if (!$scope.sheets.sheetSelectedId || !$scope.sheets.sheetArr.find(s => s.id === $scope.sheets.sheetSelectedId)) {
                    $scope.sheets.sheetSelectedId = $scope.sheets.sheetArr[0]?.id;
                }
            };

            $scope.addSelectedStock = function(stockId) {
                const inv = $scope.inventories.find(s => {
                    return s.obj._id === stockId;
                });

                const thisStock = $scope.stocks.find(s => {
                    return s.width === inv.obj.width && s.height === inv.obj.height;
                });

                if (thisStock) {
                    thisStock.number += 1;
                } else {
                    $scope.stocks.push({
                        width: inv.obj.width,
                        height: inv.obj.height,
                        number: 1
                    });
                }
            };

            function getBorder(val) {
                switch (val) {
                    case 0:
                        return 'none';
                    case 1:
                        return '2px solid #9d4103';
                    case 2:
                        return '4px solid #9d4103';
                    default:
                        return 'none';
                }
            }
            // END new design

            $scope.submit = () => {
                $rootScope.processing = true;

                $http.post('/cutlist', {
                    stocks: $scope.stocks,
                    parts: $scope.details.filter(i => !i.disabled).map(item => {
                        return {
                            w: item.width,
                            h: item.height,
                            q: item.number,
                            name: item.name,
                            canRotate: item.rotate,
                            edgefl: item.edgefl,
                            edgefs: item.edgefs,
                            edgesl: item.edgesl,
                            edgess: item.edgess
                        };
                    }),
                    cutType: getWithDefault($scope.projectSettings, 'cutType', 'h'),
                    sawWidth: getWithDefault($scope.projectSettings, 'sawWidth', 0),
                    trimLeft: getWithDefault($scope.projectSettings, 'trimLeft', 0),
                    trimTop: getWithDefault($scope.projectSettings, 'trimTop', 0),
                    trimRight: getWithDefault($scope.projectSettings, 'trimRight', 0),
                    trimBottom: getWithDefault($scope.projectSettings, 'trimBottom', 0),
                    edgeOverMeasure: getWithDefault($scope.projectSettings, 'edgeOverMeasure', 0)
                }).then(({
                    data: key
                }) => {

                    checkFinished();

                    function checkFinished() {
                        $timeout(() => {
                            $http.post('/check-finished/' + key)
                                .then(({
                                    data: res
                                }) => {
                                    if (res) {
                                        $rootScope.processing = false;
                                        $scope.dirtyState = '';
                                        if (res.err) {
                                            return $rootScope.showMessage(JSON.stringify(res.err));
                                        }

                                        $scope.result.arr = res.arr;
                                        $scope.result.wasteVsUsage = res.wasteVsUsage;
                                        if ($scope.project) {
                                            $scope.project.setResult(res.arr, res.wasteVsUsage);
                                        }
                                    } else {
                                        checkFinished();
                                    }
                                }, handleError);
                        }, 1000);
                    }
                }, handleError);
            };

            $scope.createPdf = function() {
                pdfListener();
            };

            $scope.login = function(username, password) {
                $http.post('/login', {
                    username,
                    password
                }).then(function() {
                    $scope.isLoggedIn = true;
                    logInAndLoadProjects(username);
                }, handleError);
            };

            $scope.noop = function (event) {
                event.stopPropagation();
            }

            function initInventories() {
                Inventories.loadAll((err, inventories) => {
                    $rootScope.processing = false;
                    if (err) {
                        return handleError(err);
                    }

                    $scope.inventories = inventories;
                    $scope.stocks = $scope.inventories.map(i => Object.assign({}, i.obj));

                    $scope.changeSelectedStock();
                });
            }

            function logInAndLoadProjects(username, project) {
                $rootScope.processing = true;
                $scope.username = username;
                Projects.loadAll((err, projects) => {
                    if (err) {
                        $rootScope.processing = false;
                        return handleError(err);
                    }

                    $scope.projects = projects;
                    if (!$scope.projects.length) {
                        $scope.createProject({ name: 'Example project' }, err => {
                            $rootScope.processing = false;

                            if (err) {
                                return handleError(err);
                            }

                            $scope.project = project;
                            initInventories();
                        });
                    } else {
                        $rootScope.processing = false;
                    }
                });
            }

            $scope.logout = function() {
                $http.post('/logout').then(function() {
                    window.isLoggedIn = false;
                    $scope.isLoggedIn = false;
                    $scope.tabs.shift();
                    logoutUserAndResetProjects();
                }, handleError);
            };

            function logoutUserAndResetProjects() {
                delete $scope.username;
                delete $scope.password;
                $scope.projects = [];
                initInventories();

                init(handleError);
            }

            $scope.newProject = function() {
                var modalInstance = $uibModal.open({
                    templateUrl: '/views/dialogs/project.html',
                    controller: 'ProjectCtrl',
                    animation: false,
                    resolve: {
                        title: () => 'Create_new_project',
                        actionButtonLabel: () => 'Create',
                        name: () => void 0
                    }
                });

                modalInstance.result.then(function(name) {
                    var obj = {
                        name
                    };
                    if (!name) {
                        return;
                    }

                    $rootScope.processing = true;

                    $scope.createProject(obj);
                });
            };

            $scope.createProject = function (obj, cb = () => {}) {
                Projects.createNew(obj, $scope.stocks, $scope.details, $scope.result, $scope.projectSettings, (err, project) => {
                    $rootScope.processing = false;

                    if (err) {
                        return handleError(err);
                    }

                    $scope.project = project;
                    $scope.stocks = project.plan.stocks;
                    $scope.details = project.plan.details;

                    $scope.projects.push(project);

                    initProject();

                    cb();
                });
            }

            $scope.changeProjectName = function() {
                var modalInstance = $uibModal.open({
                    templateUrl: '/views/dialogs/project.html',
                    controller: 'ProjectCtrl',
                    animation: false,
                    resolve: {
                        title: () => 'Change_project_name',
                        name: () => $scope.project.obj.name,
                        actionButtonLabel: () => 'Save_changes'
                    }
                });

                modalInstance.result.then(function(name) {
                    $rootScope.processing = true;

                    $scope.project.obj.name = name;

                    $scope.project.save((err, project) => {
                        $rootScope.processing = false;

                        if (err) {
                            return handleError(err);
                        }

                        $scope.project = project;
                        initProject();
                    });
                });
            };

            $scope.loadProject = function(project) {
                if ($scope.project && $scope.project.hasChanged()) {
                    if (askToContinue()) {
                        $scope.project.resetChanges();
                        $scope.project = project;
                        initProject();
                    }
                } else {
                    $scope.project = project;
                    initProject();
                }
            };

            function initProject() {
                const project = $scope.project;
                $scope.projectSettings = {
                    cutType: project.obj.cutType,
                    sawWidth: project.obj.sawWidth,
                    trimLeft: project.obj.trimLeft,
                    trimTop: project.obj.trimTop,
                    trimRight: project.obj.trimRight,
                    trimBottom: project.obj.trimBottom,
                    edgeOverMeasure: project.obj.edgeOverMeasure
                };
                $scope.detailsOptions = (project.plan || {
                    details: []
                }).details.map(detail => detail.name);
                $scope.stocks = (project.plan || {
                    stocks: []
                }).stocks;
                $scope.details = (project.plan || {
                    details: []
                }).details;

                if(project.result) {
                    cont(null, project.result)
                } else {
                    emptyResult(cont);
                }

                function cont (err, result) {
                    if(err) {
                        return handleError(err);
                    };
                    $scope.result = result;
                    $scope.recompileAllTooltips();

                    $scope.leftColLabel = 'Projects';

                    $scope.tabs[0] = {
                        _id: project.obj._id,
                        title: project.obj.name
                    };

                    $scope.dirtyState = '';
                }
            }

            $scope.saveProject = function() {
                if (!$scope.project) {
                    return $rootScope.showMessage($translate.instant('Create_or_load_first_alert'));
                }

                $scope.project.obj.cutType = $scope.projectSettings.cutType;
                $scope.project.obj.sawWidth = $scope.projectSettings.sawWidth;
                $scope.project.obj.trimLeft = $scope.projectSettings.trimLeft;
                $scope.project.obj.trimTop = $scope.projectSettings.trimTop;
                $scope.project.obj.trimRight = $scope.projectSettings.trimRight;
                $scope.project.obj.trimBottom = $scope.projectSettings.trimBottom;
                $scope.project.obj.edgeOverMeasure = $scope.projectSettings.edgeOverMeasure;

                $rootScope.processing = true;
                $scope.project.save((err, project) => {
                    $rootScope.processing = false;
                    if (err) {
                        return handleError(err);
                    }

                    $scope.project = project;
                    initProject();
                });
            };

            $scope.deleteProject = function() {
                if (!$scope.project) {
                    return;
                }

                $rootScope.showConfirm($translate.instant('Confirm_deletion_alert') + ` ${$scope.project.obj.name}`, ok => {
                    if (ok) {
                        $rootScope.processing = true;

                        $scope.project.delete(err => {
                            $rootScope.processing = false;

                            if (err) {
                                return handleError(err);
                            }

                            const idx = $scope.projects.findIndex(p => p.obj._id === $scope.project.obj._id);
                            $scope.projects.splice(idx, 1);

                            delete $scope.stocks;
                            delete $scope.details;
                            delete $scope.result;

                            if (idx - 1 >= 0) {
                                $scope.project = $scope.projects[idx - 1];
                                initProject();
                            } else if ($scope.projects.length) {
                                $scope.project = $scope.projects[idx];
                                initProject();
                            } else {
                                $scope.project = null;
                                $scope.leftColLabel = 'Select_project';
                            }
                        });
                    }
                });
            };

            $scope.maybeLogin = function(ev, username, password) {
                if (ev.originalEvent.keyCode === 13) {
                    $scope.login(username, password);
                }
            };

            $scope.toggleAllEdges = function(edge) {
                var modalInstance = $uibModal.open({
                    templateUrl: '/views/dialogs/change-all-edges.html',
                    controller: 'ToggleAllEdgesCtrl',
                    animation: false
                });

                modalInstance.result.then(function(val) {
                    $scope.details.forEach(detail => {
                        detail[edge] = val;
                    });
                });
            };

            $scope.afterHOTInit = function() {
                $scope.hot = this;
            };

            $scope.beforeRenderer = function(td, row, _col, _prop, _value, _cellProperties) {
                const tr = window.$(td).parent();
                tr.addClass('divTableRow rz-tooltip-container');
                if (row % 2) {
                    tr.addClass('whitish');
                } else {
                    tr.addClass('first-table-row');
                }

                if ($scope.details[row].disabled) {
                    tr.addClass('divTableCellDisabled');

                    td.innerHTML = '<i class="fa fa-times fa-2x red-cross"></i>';
                } else {
                    const $newScope = $scope.$new(false);
                    $newScope.row = row;
                    $compile(tr)($newScope);

                    td.innerHTML = `${row + 1}.`;
                }
            };

            $scope.afterOnCellMouseDown = function(ev) {
                if (/th/i.test(ev.target.nodeName)) {
                    return;
                }

                const coords = $scope.hot.getSelected()[0];
                const row = coords[0];
                const col = coords[1];

                onAction(ev, row, col);
            };

            $scope.onBeforeKeyDown = function(ev) {
                if (ev.keyCode === 13) { // Enter
                    const coords = $scope.hot.getSelected()[0];
                    const row = coords[0];
                    const col = coords[1];

                    onAction(ev, row, col);
                }
            };

            function changeTextAreaMode() {
                if (document.querySelector('.handsontableInputHolder') && document.querySelector('.handsontableInputHolder').childNodes) {
                    if (!document.querySelector('.handsontableInputHolder').childNodes[0].hasAttribute('inputmode')) {
                        document.querySelector('.handsontableInputHolder').childNodes[0].setAttribute('inputmode', 'numeric');
                    }
                }
            }

            function startCustomEdit() {
                $scope.hot.getActiveEditor().beginEditing();
                changeTextAreaMode();
                document.addEventListener('focusout', exitCustomEdit);
            }

            function exitCustomEdit() {
                $scope.hot.deselectCell();
                document.body.removeEventListener('touchend', startCustomEdit);
                document.body.removeEventListener('focusout', exitCustomEdit);
            }

            $scope.afterSelection = function(row, col, _row2, _col2, _preventScrolling, _selectionLayerLevel) {
                if (col === NUMBER_COL || col === WIDTH_COL || col === HEIGHT_COL) {
                    document.body.addEventListener('touchend', startCustomEdit);
                }
            };

            $scope.afterBeginEditing = function(_row, _col) {
                if (document.querySelector('.handsontableInputHolder') && document.querySelector('.handsontableInputHolder').childNodes) {
                    document.querySelector('.handsontableInputHolder').childNodes[0].select();
                }
            };

            $scope.setResultDirty = function () {
                if ($scope.result.arr.length) {
                    $scope.dirtyState = 'Invalid';
                }
            };

            $scope.beforeChange = function (ev, source) {
                if (source === 'loadData') {
                    return;
                }

                $scope.setResultDirty();
            };

            $scope.openRegistration = function() {
                window.open('/register', '_self');
            };

            function onAction(ev, row, col) {
                if (EDGE_FL_COL <= col && col <= EDGE_SS_COL || col === DISABLE_COL || col === DELETE_COL) {
                    $scope.setResultDirty();
                }

                if (EDGE_FL_COL <= col && col <= EDGE_SS_COL || col === DISABLE_COL || col === DELETE_COL) {
                    ev.stopImmediatePropagation();

                    $scope.$apply(function() {
                        switch (col) {
                            case EDGE_FL_COL:
                                $scope.details[row].edgefl += 1;
                                $scope.details[row].edgefl %= 3;
                                break;
                            case EDGE_FS_COL:
                                $scope.details[row].edgefs += 1;
                                $scope.details[row].edgefs %= 3;
                                break;
                            case EDGE_SL_COL:
                                $scope.details[row].edgesl += 1;
                                $scope.details[row].edgesl %= 3;
                                break;
                            case EDGE_SS_COL:
                                $scope.details[row].edgess += 1;
                                $scope.details[row].edgess %= 3;
                                break;
                            case DISABLE_COL:
                                $scope.deactivateRow(row);
                                break;
                            case DELETE_COL:
                                $scope.deleteRow(row);
                                break;
                        }
                    });
                }
            }

            function handleError(err) {
                $rootScope.processing = false;
                if (err instanceof Errors.DuplicateError) {
                    $rootScope.showMessage($translate.instant('Duplicate_element'));
                } else {
                    if (err.data) {
                        err = err.data;
                    }

                    if (err.error) {
                        err = err.error;
                    }

                    if (typeof err !== 'string') {
                        err = JSON.stringify(err);
                    }

                    $rootScope.showMessage($translate.instant(err));
                }
                console.error(err);
            }

            $scope.showCuttingPlan = function () {
                if ($scope.dirtyState) {
                    $rootScope.showMessage($translate.instant('You_are_seeing_an_ivalid_cutting_plan'));
                }
                $scope.showResult = true;
            };

            $scope.isLoggedIn = window.isLoggedIn;

            if ($scope.isLoggedIn) {
                logInAndLoadProjects(window.USERNAME);
            } else {
                logInAndLoadProjects('');
            }

            window.document.body.style.visibility = 'visible';

            window.addEventListener('beforeunload', function(event) {
                if (hasChanges() && askToContinue()) {
                    event.preventDefault();
                }
            });

            $scope.popoverTemplate = '/views/partials/visualization-tooltip.html';
            $scope.disableTemplate = '/views/partials/disable-button.html';
            $scope.deleteTemplate = '/views/partials/delete-button.html';

            function hasChanges() {
                return $scope.project && $scope.project.hasChanged();
            }

            function askToContinue() {
                return confirm(`${$translate.instant('Partial_You_have_unsaved_changes_for')} ${$scope.project.obj.name}, ${$translate.instant('Partial_do_you_want_to_continue')}`);
            }

            function askToResetCuttingPlan() {
                return confirm(`${$translate.instant('Partial_This_action_will_delete_the_current_cutlist')} ${$scope.project.obj.name}, ${$translate.instant('Partial_do_you_want_to_proceed')}`);
            }

            function hotRenderer(instance, td, row, col, _prop, _value, _cellProperties) {
                if (col === 0) {
                    const tr = window.$(td).parent();

                    tr.off('mouseenter');
                    tr.off('mouseout');
                    tr.off('keypress');

                    tr.attr('uib-popover-template', 'popoverTemplate');
                    tr.attr('popover-trigger', '\'mouseenter\'');
                    tr.attr('popover-placement', $scope.details[row].width > $scope.details[row].height ? 'bottom' : 'left');
                    tr.attr('popover-append-to-body', true);

                    $scope.recompileTooltip(row);
                }
            }

            function hotDisableRenderer(instance, td, row) {
                renderCell(td, row, $scope.disableTemplate);
            }

            function hotDeleteRenderer(instance, td, row) {
                renderCell(td, row, $scope.deleteTemplate);
            }

            function renderCell(td, row, template) {
                td.innerHTML = `<div class="action-icon-wrapper" ng-include="'${template}'"/>`;

                const $newScope = $scope.$new(false);
                $newScope.row = row;

                $compile(td)($newScope);
            }

            $scope.addInventory = function(inventory) {
                $rootScope.processing = true;

                Inventories.createNew({
                    width: Number(inventory.width),
                    height: Number(inventory.height),
                    number: 1
                }, (err, inventory) => {
                    $rootScope.processing = false;

                    if (err) {
                        return handleError(err);
                    }

                    $scope.inventories.push(inventory);
                        $scope.changeSelectedStock();
                });
            };

            $scope.removeInventory = function(inventory) {
                $rootScope.processing = true;

                inventory.delete(err => {
                    $rootScope.processing = false;

                    if (err) {
                        handleError(err);
                    }

                    const idx = $scope.inventories.findIndex(i => i.obj._id === inventory.obj._id);
                    $scope.inventories.splice(idx, 1);
                        $scope.changeSelectedStock();
                });
            };

            $scope.resetPassword = function() {
                var modalInstance = $uibModal.open({
                    templateUrl: '/views/dialogs/reset-password.html',
                    controller: 'ResetPasswordCtrl',
                    animation: false
                });

                modalInstance.result.then(function(email) {
                    $rootScope.processing = true;

                    $http.post('/forgot-password-request', {
                        email: email
                    }).then(() => {
                        $rootScope.showMessage($translate.instant('An email has been sent with a reset password link'));
                        $rootScope.processing = false;
                    }, handleError);
                });
            };
        }

        function getWithDefault(obj, prop, dflt) {
            return obj[prop] || dflt;
        }

        const middleEl = document.getElementById('middle-wrapper');

        const el = document.getElementById('main-wrapper');
        el.scrollLeft = middleEl.offsetWidth;

        document.getElementById('left-col-button').addEventListener('click', () => {
            el.scrollLeft -= middleEl.offsetWidth;
        });

        document.getElementById('right-col-button').addEventListener('click', () => {
            el.scrollLeft += middleEl.offsetWidth;
        });

        document.getElementById('menu').addEventListener('click', () => {
            if (document.getElementById('menu').checked === true) {
                document.body.classList.add('lock-scroll');
            } else {
                document.body.classList.remove('lock-scroll');
            }
        });

        let mainWrapper = document.getElementById('main-wrapper');
        let mainItems = document.querySelectorAll('.main-wrapper-items');
        let leftTag = document.getElementById('left-tag');
        let rightTag = document.getElementById('right-tag');
        let leftButton = document.getElementById('left-col-button');
        let rightButton = document.getElementById('right-col-button');
        let footer = document.getElementById('footer');

        const observer = new IntersectionObserver(
            (items) => {
                items.forEach(item => {
                    if (item.isIntersecting) {
                        if (item.intersectionRatio >= 0.40 && item.target.id === 'left-wrapper') {
                            leftButton.setAttribute('disabled', '');
                            rightButton.removeAttribute('disabled', '');
                            leftTag.setAttribute('left-content', '');
                            rightTag.setAttribute('right-content', '\uf054');
                            mainWrapper.classList.remove('main-wrapper-short');
                            mainWrapper.classList.add('main-wrapper-long');
                            footer.classList.add('foot-row-static');
                            footer.classList.remove('foot-row-fixed');
                        } else if (item.intersectionRatio >= 0.75 && item.target.id === 'middle-wrapper') {
                            leftButton.removeAttribute('disabled', '');
                            rightButton.removeAttribute('disabled', '');
                            leftTag.setAttribute('left-content', '\uf053');
                            rightTag.setAttribute('right-content', '\uf054');
                            mainWrapper.classList.remove('main-wrapper-long');
                            mainWrapper.classList.add('main-wrapper-short');
                            footer.classList.add('foot-row-fixed');
                            footer.classList.remove('foot-row-static');
                        } else if (item.intersectionRatio >= 0.75 && item.target.id === 'right-wrapper') {
                            rightButton.setAttribute('disabled', '');
                            leftButton.removeAttribute('disabled', '');
                            leftTag.setAttribute('left-content', '\uf053');
                            rightTag.setAttribute('right-content', '');
                            mainWrapper.classList.remove('main-wrapper-long');
                            mainWrapper.classList.add('main-wrapper-short');
                            footer.classList.add('foot-row-fixed');
                            footer.classList.remove('foot-row-static');
                        }
                    }
                });
            }, options);

        if (window.innerWidth < 769) {
            mainItems.forEach(item => {
                if (item) {
                    observer.observe(item);
                }
            });
        }

        function emptyResult (cb) {
            $http.post('/results', {
                arr: [],
                wasteVsUsage: []
            }).then(({data: result}) => {
                cb(null, result);
            }, cb);
        }
    }
]);
