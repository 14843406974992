const app = angular.module('cutlist');

app.controller('ProjectCtrl', [
    '$scope',
    '$uibModalInstance',
    'title',
    'actionButtonLabel',
    'name',
    function(
        $scope,
        $uibModalInstance,
        title,
        actionButtonLabel,
        name
    ) {

        $scope.title = title;
        $scope.actionButtonLabel = actionButtonLabel;
        $scope.name = name;
        $scope.submit = function(ev) {
            ev.preventDefault();

            $uibModalInstance.close($scope.name);
        };
    }
]);
