const app = angular.module('cutlist');

app.controller('ToggleAllEdgesCtrl', ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
    $scope.val = 0;

    $scope.submit = function(ev) {
        ev.preventDefault();

        $uibModalInstance.close(parseInt($scope.val));
    };
}]);
