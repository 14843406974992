angular.module('cutlist')
.directive('alertMessage', ['$rootScope', ($rootScope) => {
    return {
        restrict: 'E',
        replace: true,
        scope: {
            text: '='
        },
        link: ($scope, el) => {
            $rootScope.showMessage = function (text) {
                $scope.text = text;
                el['0'].show();
            };

            if(el['0']) {
                window.addEventListener('click', function(e){
                    if(el['0'].open) {
                        if(e.target.id === 'dialog'){
                            el['0'].close();
                        }
                    }
                }, false);
            }

            closeMessage = (event) => {
                event.preventDefault();
                el['0'].close();
            }
        },
        templateUrl: '/views/partials/alert-message.html'
    };
}]);

