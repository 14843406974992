const app = angular.module('cutlist');

app.directive('rzResultContainer', ['$translate', function($translate) {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: function($scope, el, attr) {
            $scope.scale = Number(attr.scale);
            $scope.isPreview = attr.isPreview === 'true';

            var canvasListeners = [];

            $scope.registerPdfListener(() => {
                var doc = new window.jspdf.jsPDF();
                canvasListeners.forEach((listener, idx) => {
                    var image = listener();
                    doc.addImage(image, 'png', 10, 20, 192, 0, '', 'FAST');
                    if (idx < canvasListeners.length - 1) {
                        doc.addPage();
                    }
                });
                doc.save($translate.instant('Cutlist') + '-' + new Date().toDateString() + '.pdf');
            });

            $scope.resetCanvasListeners = () => {
                canvasListeners = [];
            };

            $scope.registerCanvasListener = (listener) => {
                canvasListeners.push(listener);
            };
        },
        templateUrl: '/views/partials/result-container.html'
    };
}]);
