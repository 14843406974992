angular.module('cutlist').service('Inventories', ['$http', 'Inventory', 'BaseEntity', function ($http, Inventory, BaseEntity) {
    var Inventories = function () {
    };

    Object.assign(Inventories.prototype, BaseEntity.prototype);
    Inventories.prototype.constructor = Inventories;

    Inventories.prototype.createNew = function (obj, callback) {
        $http.post('/inventories', obj)
            .then(({data: inventory}) => {
              const newInventory = new Inventory(inventory);
              callback(null, newInventory);
            }, err => this.processError(err, null, callback));
    };

    Inventories.prototype.loadAll = function (callback) {
        $http.get('/inventories')
            .then(({data: inventories}) => {
                callback(null, inventories.map(i => new Inventory(i)));
            }, err => this.processError(err, null, callback));
    };

    return new Inventories();
}]);
