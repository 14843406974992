angular.module('cutlist').service('Projects', ['$http', 'Project', 'BaseEntity', function ($http, Project, BaseEntity) {
    var Projects = function () {
    };

    Object.assign(Projects.prototype, BaseEntity.prototype);
    BaseEntity.prototype.constructor = Projects;

    Projects.prototype.createNew = function (obj, stocks, details, cutlist, settings, callback) {
        var plan;
        var result;
        var newProject;
        window.async.waterfall([
            (cb) => {
                $http.post('/plans', {
                    stocks: stocks,
                    details: details
                }).then(({data: plan}) => {
                    cb(null, plan);
                }, cb);
            },
            (newPlan, cb) => {
                plan = newPlan;
                if (cutlist) {
                    $http.post('/results', stripIds(cloneDeep(cutlist)))
                        .then(({data: result}) => {
                            cb(null, result);
                        }, cb);
                } else {
                    cb(null, {_id: void 0});
                }
            },
            (newResult, cb) => {
                result = newResult;
                $http.post('/projects', {
                    name: obj.name,
                    planId: plan._id,
                    cutType: settings.cutType,
                    sawWidth: settings.sawWidth,
                    trimLeft: settings.trimLeft,
                    trimTop: settings.trimTop,
                    trimRight: settings.trimRight,
                    trimBottom: settings.trimBottom,
                    resultId: result._id
                }).then(({data: project}) => cb(null, project), cb);
            }
        ], (err, project) => {
            newProject = new Project(project, plan, result);
            this.processError(err, newProject, callback);
        });
    };

    Projects.prototype.loadAll = function (callback) {
        $http.get('/projects')
            .then(({data: projects}) => {
                window.async.map(projects, (project, cb) => {
                    this.load(project, cb);
                }, (err, projects) => {
                    if (err) {
                        return callback(err);
                    }
                    callback(null, projects);
                });
            }, err => this.processError(err, null, callback));
    };

    Projects.prototype.load = function (obj, callback) {
        var plan, result;
        window.async.waterfall([
            cb => {
                $http.get('/plans/' + obj.planId)
                    .then(({data: newPlan}) => {
                        plan = newPlan;
                        cb();
                    }, cb);
            },
            cb => {
                if (obj.resultId) {
                    $http.get('/results/' + obj.resultId)
                        .then(({data: newResult}) => {
                            result = newResult;
                            cb();
                        }, cb);
                } else {
                    cb();
                }
            }
        ], err => {
            var project = new Project(obj, plan, result);
            return this.processError(err, project, callback);
        });
    };

    // Dupe
    function cloneDeep (obj) {
        return window._.cloneDeep(obj);
    }

    function stripIds (obj) {
        if (!obj) {
            return null;
        }

        if (obj._id) {
            delete obj._id;
        }

        Object.values(obj).forEach(prop => Object.prototype.hasOwnProperty.call(obj, prop) && stripIds(prop));

        return obj;
    }

    return new Projects();
}]);
