angular.module('cutlist').service('ToggleVisibility', [function () {

    /***Password visibility***/
    const toggleVisibility = function($event) {
        const id = $event.target.id.replace('show', '');
        const el = document.getElementById(id);
        if ($event.target.checked) {
            el.type = "text";
        } else {
            el.type = "password";
        }
    }

    return {
        toggleVisibility
    };
}]);
