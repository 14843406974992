angular.module('cutlist').service('Errors', [function () {
    const DuplicateError = function (err) {
        this.error = err;
    };

    Object.assign(DuplicateError.prototype, Error.prototype);
    DuplicateError.prototype.constructor = DuplicateError;

    return {
        DuplicateError
    };
}]);
