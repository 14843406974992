var app = angular.module('cutlist');

app.directive('rzResultDetails', ['$translate', function($translate) {
    const EDGE_THIN = 1;
    const EDGE_THICK = 2;

    const COLOR_ONE = 'rgba(35, 113, 149, 1)';
    const COLOR_TWO = 'rgba(0, 46, 69, 1)';

    const DEFAULT_PAGE_HEIGHT_PX = 1000;
    const DEFAULT_ROW_HEIGHT_PX = 45;

    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: function($scope, element, attributes) {
            if (!$scope.isPreview) {
                $scope.canvasClass = 'col-sm-offset-1 col-sm-10 col-md-9';
            }

            $scope.$watch('result', function(result) {
                // Removes existing cut list in case a new one is created via the create cutlist button
                if (document.querySelectorAll('.cutlist-canvas').length > 0) {
                    document.querySelectorAll('.cutlist-canvas').forEach(canvas => canvas.parentNode.removeChild(canvas));
                }

                if (!result || !result.arr || !$scope.result.arr.length) {
                    return;
                }

                $scope.comms.detailsDone = false;

                let currentCanvas;
                let currentCTX;

                const { totalAreaPC, totalUsagePC } = getCuttingPlanStats();

                // Stats page
                let positionX = 20;
                let positionY = 40;

                let projectName = getProjectName();
                let orderedDate = getDate();

                writeTextRow(`${projectName}, ${orderedDate}`, 22, 1);
                writeTextRow(`${ti('Surface_total')}: ${totalAreaPC.toFixed(2)}%`, 22, 2);
                writeTextRow(`${ti('Usage_total')}: ${totalUsagePC.toFixed(2)}%`, 22, 1);
                writeTextRow(`${ti('Edging thin')}: ${getEdgingThin(result.arr)} ${ti('MM')}`, 22, 2);
                writeTextRow(`${ti('Edging thick')}: ${getEdgingThick(result.arr)} ${ti('MM')}`, 22, 1);

                let currentColor;
                for (let i = 0; i < result.arr.length; i++) {
                    let text = `${ti('Slate')} ${i+1}` + getDimensions(i) + getArea(i) + getUsage(i);
                    writeTextRow(text, 22, i);

                    currentColor = i;
                }

                let headerText = `${ti('Detail')}` + (`${ti('Count')}`).padStart(30 + 6) + (`${ti('MM')}`).padStart(11) +
                    (`${ti('Turn')}`).padStart(16) + (` ${ti('Edges')} `).padStart(18);
                writeTextRow(headerText, 20, currentColor + 1); //current color will be same as next portion

                $scope.details.forEach((item, idx) => {
                    let rotate;

                    if (item.rotate) {
                        rotate = `${ti('Yes')}`;
                    } else {
                        rotate = `${ti('No')}`;
                    }

                    let itemName = item.name;
                    if (itemName.length > 30) {
                        itemName = itemName.slice(0, 27).padEnd(30, '.');
                    } else {
                        itemName = itemName.padEnd(30);
                    }

                    let text = (`${item.number}`).padStart(10) + (`${item.width}x${item.height}`).padStart(15) +
                        (`${rotate}`).padStart(12) +
                        (` 1${ti('Long')}-${item.edgefl}`).padStart(10) + (` 1${ti('Short')}-${item.edgefs}`).padStart(6) +
                        (` 2${ti('Long')}-${item.edgesl}`).padStart(6) + (` 2${ti('Short')}-${item.edgess}`).padStart(6);
                    writeTextRow(itemName + text, 20, currentColor + idx);
                });

                $scope.resetCanvasListeners();

                let canvases = document.querySelectorAll('.cutlist-canvas');
                var imageObj = new Image();
                imageObj.onload = function() {
                    for (let i = 0; i < canvases.length; i++) {
                        $scope.registerCanvasListener(() => {
                            var imageDetails = new Image();
                            imageDetails.src = canvases[i].toDataURL('image/png');
                            return imageDetails;
                        });
                    }

                    $scope.$apply(() => {
                        $scope.comms.detailsDone = true;
                    });
                };
                imageObj.src = '/img/fladder-small1.png';

                function getDimensions (i) {
                    return (`${result.arr[i].L}x${result.arr[i].W}`).padStart(20);
                }

                function getArea (i) {
                    return (`${ti('Surface_area')}:`).padStart(24) + (`${$scope.waste[i].area}%`).padStart(7);
                }

                function getUsage (i) {
                    return (`${ti('Usage')}:`).padStart(22) + (`${$scope.waste[i].usage}%`).padStart(7);
                }

                function ti (text) {
                    return $translate.instant(text);
                }

                function getCuttingPlanStats () {
                    $scope.waste = JSON.parse(attributes.waste);
                    //Total area surface
                    const totalArea = result.arr.reduce((acc, item) => {
                        return acc + item.W * item.L;
                    }, 0);
                    //Total area percentage
                    const totalAreaPC = $scope.waste.reduce((acc, waste, i) => {
                        const item = result.arr[i];
                        return acc + Number(waste.area) * ((item.W * item.L) / totalArea);
                    }, 0);
                    //Total usage percentage
                    const totalUsagePC = $scope.waste.reduce((acc, waste, i) => {
                        const item = result.arr[i];
                        return acc + Number(waste.usage) * ((item.W * item.L) / totalArea);
                    }, 0);

                    return {
                        totalAreaPC,
                        totalUsagePC
                    };
                }

                function getProjectName () {
                    return (($scope.project || {
                        obj: {
                            name: ''
                        }
                    }).obj.name).toUpperCase() || ti('Unnamed project');
                }

                function getDate () {
                    let date = new Date();
                    return `${date.getFullYear()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${('0' + date.getDate()).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`;
                }

                function writeTextRow (text, fontsize, idx) {
                    if (positionY + DEFAULT_ROW_HEIGHT_PX > DEFAULT_PAGE_HEIGHT_PX || !currentCTX) {
                        const canvasContainer = document.getElementById('canvasContainer');
                        currentCanvas = createCanvas(canvasContainer, idx);
                        currentCTX = currentCanvas.getContext('2d');
                        positionY = 0;
                    }

                    const startY = positionY;
                    const startX = positionX;

                    currentCTX.save();
                    //stripes
                    if (!(idx % 2)) {
                        currentCTX.fillStyle = COLOR_ONE;
                    } else {
                        currentCTX.fillStyle = COLOR_TWO;
                    }
                    currentCTX.fillRect(0, startY, currentCanvas.width, DEFAULT_ROW_HEIGHT_PX);
                    //text, even that a bold fontface is used, it is not working consistently that is why 'bold' was added, maybe remove the fontface
                    currentCTX.font = 'bold ' + fontsize + 'px DejaVuSansMonoBold, monospace';
                    currentCTX.textAlign = 'start';
                    currentCTX.textBaseline = 'top';
                    currentCTX.fillStyle = '#e0e7ef';
                    currentCTX.fillText(text, startX, startY + 10);

                    positionY += DEFAULT_ROW_HEIGHT_PX;

                    currentCTX.restore();
                }
            }, true);

            function createCanvas (canvasContainer, id) {
                let addCanvas = document.createElement('canvas');
                addCanvas.className = `${$scope.canvasClass} cutlist-canvas`;
                addCanvas.id = id;

                addCanvas.style.maxWidth = '100%';
                addCanvas.style.minWidth = '70%';
                //Fixed width for drawing on canvases, so it will display properly when printed on a A4 page
                addCanvas.width = 1200;
                addCanvas.height = DEFAULT_PAGE_HEIGHT_PX;

                canvasContainer.insertAdjacentElement('beforeend', addCanvas);

                return addCanvas;
            }

            function getEdgingThin(arr) {
                return getEdging(arr, EDGE_THIN);
            }

            function getEdgingThick(arr) {
                return getEdging(arr, EDGE_THICK);
            }

            function getEdging(arr, type) {
                return arr.reduce((outerAcc, stock) => {
                    return outerAcc + stock.result.reduce((acc, el) => {
                        let res = acc;
                        let gotAny = false;
                        for (const edging of [
                                ['edgefl', 'w'],
                                ['edgefs', 'h'],
                                ['edgesl', 'w'],
                                ['edgess', 'h']
                            ]) {
                            if (el[edging[0]] === type) {
                                res += el.item[edging[1]];
                                gotAny = true;
                            }
                        }

                        if (gotAny) {
                            res += $scope.projectSettings.edgeOverMeasure;
                        }

                        return res;
                    }, 0);
                }, 0);
            }
        },
        templateUrl: '/views/partials/result-details.html'
    };
}]);
