var app = angular.module('cutlist');

app.controller('SettingsCtrl', ['$rootScope', '$scope', '$http', '$window', '$translate', 'ToggleVisibility', function($rootScope, $scope, $http, $window, $translate, ToggleVisibility) {
    $scope.username = window.username;

    // TODO: dedupe with above
    function handleError(err) {
        $rootScope.processing = false;
        if (err.data) {
            err = err.data;
        }

        if (err.error) {
            err = err.error;
        }

        if (typeof err !== 'string') {
            err = JSON.stringify(err);
        }

        $rootScope.showMessage($translate.instant(err));
        console.log(err);
    }

    /***Password visibility***/
    $scope.toggleVisibility = ToggleVisibility.toggleVisibility;

    $scope.changeLanguage = function(ev, langKey) {
        $translate.use(langKey);
    };

    $scope.updatePassword = function() {
        if ($scope.password !== $scope.confirmPassword) {
            return $rootScope.showMessage($translate.instant('Passwords don\'t match.'));
        }

        $http.post('/update-password', {
            password: $scope.password
        }).then(function() {
            $rootScope.showMessage($translate.instant('Saved'));
        }, handleError);
    };
}]);
