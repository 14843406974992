angular.module('cutlist').service('BaseEntity', ['Errors', function (Errors) {
    const BaseEntity = function () {
    };

    BaseEntity.prototype.processError = function (err, obj, callback) {
        if (err?.data?.error) {
            if (/E11000/.test(err.data.error)) {
                return callback(new Errors.DuplicateError(err.data.error));
            }
        }

        return callback(err, obj);
    };

    return BaseEntity;
}]);
