const app = angular.module('cutlist');

app.directive('rzResult', ['$translate', function($translate) {
    return {
        restrict: 'E',
        replace: true,
        scope: true,
        link: function($scope, element, attributes) {
            if (!$scope.isPreview) {
                $scope.canvasClass = 'col-sm-offset-1 col-sm-10 col-md-9';
            }

            $scope.idx = parseInt(attributes.idx);
            $scope.waste = JSON.parse(attributes.waste);

            var canvas = element.find('canvas')[0];

            $scope.$watch('comms.detailsDone', function(detailsDone) {
                if (!detailsDone) {
                    return;
                }

                if (!$scope.result || !$scope.result.arr || !$scope.result.arr.length) {
                    return;
                }

                var ctx = canvas.getContext('2d');

                var slateW = $scope.slateSolution.W;
                var slateL = $scope.slateSolution.L;

                var textWidth = 620;
                var textHeight = 50;
                var textYOffset = textHeight + 30;
                //Fixed width for drawing on canvases, so it will display properly when printed on a A4 page
                canvas.width = 1200;
                canvas.height = (((canvas.width / slateW) * (slateL + textYOffset) + 1));

                ctx.setTransform(1, 0, 0, 1, 0, 0);
                ctx.textAlign = 'center';
                ctx.font = '45px Verdana';

                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.strokeStyle = 'black';

                if ($scope.isPreview) {
                    ctx.lineWidth = 10;
                }

                var ratio = (canvas.width / Math.max(...$scope.result.arr.map(slate => slate.W)));
                ctx.setTransform(ratio, 0, 0, ratio, 0, 0);

                if (!$scope.isPreview) {
                    ctx.fillText(`${$scope.idx + 1}: ${$scope.slateSolution.L}x${$scope.slateSolution.W}, ` + `${$translate.instant('Surface_area')}: ${$scope.waste.area}%, ${$translate.instant('Usage')}: ${$scope.waste.usage}%`, textWidth, textHeight);
                }

                // $scope.resetCanvasListeners();

                var imageObj = new Image();
                imageObj.onload = function() {
                    if (!$scope.isPreview) {
                        var imageScaledHeight = imageObj.height / ratio;
                        var imageScaledWidth = imageObj.width / ratio;
                        for (var i = 0; i < $scope.slateSolution.L / imageScaledHeight; i++) {
                            for (var j = 0; j < $scope.slateSolution.W / imageScaledWidth; j++) {
                                ctx.drawImage(imageObj, j * imageScaledWidth, textYOffset + i * imageScaledHeight, imageScaledWidth, imageScaledHeight);
                            }
                        }
                    }

                    // Cover the possible overflow with white
                    if (!$scope.isPreview) {
                        ctx.fillStyle = 'white';
                        ctx.fillRect($scope.slateSolution.W, textYOffset, canvas.width / ratio - $scope.slateSolution.W, canvas.height / ratio);
                        ctx.fillRect(0, textYOffset + $scope.slateSolution.L, canvas.width / ratio, canvas.height / ratio - $scope.slateSolution.L);
                    }
                    ctx.fillStyle = 'black';

                    ctx.strokeRect(0, textYOffset, $scope.slateSolution.W, $scope.slateSolution.L);

                    $scope.slateSolution.result.forEach(part => {
                        var w = part.item.w;
                        var h = part.item.h;
                        var x = part.x;
                        var y = part.y + textYOffset;
                        // var itemName = ctx.measureText(part.ref);
                        var wString = w.toString();
                        var hString = h.toString();

                        ctx.strokeRect(x, y, w, h);
                        if (!$scope.isPreview) {
                            if (h >= w) {
                                ctx.save();
                                ctx.translate(x, y + h / 2);
                                ctx.rotate(-Math.PI / 2);
                                ctx.translate(-x, -(y + h / 2));
                                ctx.fillText(part.ref, x, (y + h / 2 + w / 2));
                                ctx.restore();
                            } else {
                                ctx.fillText(part.ref, x + w / 2, y + h / 2);
                            }

                            if (w >= 100 || h >= 100) {
                                if (ctx.measureText(wString).width * 2 >= w || ctx.measureText(hString).width * 2 >= h) {
                                    ctx.save();
                                    ctx.scale(0.5, 0.5);
                                    ctx.translate(x, y);
                                    ctx.fillText(w, x + w, y + textYOffset);
                                    ctx.translate(x, y + h);
                                    ctx.rotate(-Math.PI / 2);
                                    ctx.translate(-x, -(y + h));
                                    ctx.fillText(h, x, (y + h) + textYOffset);
                                    ctx.restore();
                                } else {
                                    ctx.save();
                                    ctx.fillText(w, x + w / 2, y + textYOffset);
                                    ctx.translate(x, y + h / 2);
                                    ctx.rotate(-Math.PI / 2);
                                    ctx.translate(-x, -(y + h / 2));
                                    ctx.fillText(h, x, (y + h / 2) + textYOffset);
                                    ctx.restore();
                                }
                            }
                        }
                    });

                    $scope.registerCanvasListener(() => {
                        var image = new Image();
                        image.src = canvas.toDataURL('image/png');
                        return image;
                    });
                };
                imageObj.src = '/img/fladder-small1.png';
            });
        },
        templateUrl: '/views/partials/cutlist-canvas.html'
    };
}]);
